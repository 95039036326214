// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   subheadline -> text
//   download_items -> bloks / Downloads
// end of automatic

import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import {
  Document32,
  Image32,
  DocumentAudio32,
  DocumentPdf32,
  DocumentVideo32,
  Table32,
  DocumentWordProcessor32,
  Archive32,
} from "@carbon/icons-react"

const DownloadsBlock = ({ block }) => {
  let fileExtensions = {
    jpg: <Image32 className="w-12 h-auto" />,
    jpeg: <Image32 className="w-12 h-auto" />,
    png: <Image32 className="w-12 h-auto" />,
    mp3: <DocumentAudio32 className="w-12 h-auto" />,
    mp4: <DocumentVideo32 className="w-12 h-auto" />,
    pdf: <DocumentPdf32 className="w-12 h-auto" />,
    xlsx: <Table32 className="w-12 h-auto" />,
    xlsm: <Table32 className="w-12 h-auto" />,
    doc: <DocumentWordProcessor32 className="w-12 h-auto" />,
    docx: <DocumentWordProcessor32 className="w-12 h-auto" />,
    docm: <DocumentWordProcessor32 className="w-12 h-auto" />,
    zip: <Archive32 className="w-12 h-auto" />,
    rar: <Archive32 className="w-12 h-auto" />,
    file: <Document32 className="w-12 h-auto" />,
  }

  const getIconCarbon = (file) => {
    if (file) {
      let fileType = file.split(".")
      fileType = fileType[fileType.length - 1]
      if (fileExtensions[fileType]) {
        return fileExtensions[fileType]
      } else {
        return fileExtensions["file"]
      }
    }
  }

  const getFileName = (file) => {
    if (file) {
      let fileName = file.split("/")
      fileName = fileName[fileName.length - 1]
      return fileName
    }
  }

  return (
    <BlockWrapper block={block} blockWidth="container" showHeadline="true">
      <div className="flex flex-row flex-wrap -mx-grid">
        {block.download_items?.length > 0 &&
          block.download_items.map((item, index) => (
            <div className="lg:w-1/2 px-grid" key={index}>
              <div className="flex flex-row mt-8" key={index}>
                <div className="flex">
                  {item.image?.filename?.length > 0 ? (
                    <div className="w-12 mr-4">
                      <Image
                        className="block w-full h-auto"
                        image={item.image}
                      />
                    </div>
                  ) : (
                    <Link
                      className="inline-block mt-1 mr-4 text-xl font-bold hover:text-primary-hover"
                      link={item.file.filename}
                    >
                      {getIconCarbon(item.file.filename)}
                    </Link>
                  )}
                </div>
                <div>
                  <Link
                    className="block text-xl font-bold text-primary hover:text-primary-hover"
                    link={item.file.filename}
                  >
                    {item.title?.length > 0
                      ? item.title
                      : getFileName(item.file.filename)}
                  </Link>
                  <span className="inline-block prose">{item.description}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default DownloadsBlock
